.calendar-frame {
    /* Borders, Margins & Padding */
    /* margin: 80px, 5px, 5px, 5px; */
    margin: 70px, 10px, 0px, 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 25px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgb(235, 230, 230);
}

