

.auditLog {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 20px 0px 0px 0px;
    width: auto;
    box-sizing: border-box;
}

.action {
    /* background-color: #f8f4f4; */
    /* background-color: rgb(254, 255, 237); */
    background-color: white;
    /* color: white; */
    color: #1769aa;
    border: 1px solid white;

    text-align: 'left';
    /* color: "#2196f3"; */
    /* color: "red"; */
    /* border-right: solid 2px white; */
}

.actionRow {
    /* background-color: rgb(254, 255, 237); */
    border-right: 1px solid white;
    /* border: 1px solid white; */
    /* border-bottom: solid 1px black;
    border-top: solid 1px white; */
    /* background-color: #f8f4f4; */
    text-align: 'left';
}

.main-grid-rows {
    /* background-color: rgba(242,242,242,255); */
    background-color: white;
    /* border: 1px solid white;   */
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    /* row--editable:  */
    /* border-right: 1px solid lightgray; */
    /* border-bottom: 2px solid white; */
    /* background-color: #f8f4f4; */
    text-align: 'left'
}

.main-grid-rows-edit {
    background-color: rgba(242,242,242,255);
    background-color: black;
    /* background-color: white; */
    /* border: 1px solid white;   */
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    /* row--editable:  */
    /* border-right: 1px solid lightgray; */
    /* border-bottom: 2px solid white; */
    /* background-color: #f8f4f4; */
    text-align: 'left'
}

.main-grid-columns {
    /* background-color: rgba(242,242,242,255); */
    /* border: 1px solid white;   */
    border-right: 2px solid white;
    /* border-bottom: 2px solid white; */
    /* background-color: #f8f4f4; */
    /* text-align: 'left'; */
    
}

.administration {
    background-color: rgba(148,17,0,255);
    color: white;
    /* border-color: white; */
    border: 1px solid white;  
}

.flight-times {
    background-color: rgba(48,84,150,255);
    color: white;
    border: 1px solid white;  
}

.people {
    background-color: rgb(72,116,196);
    color: white;
    border: 1px solid white;  
}

.route {
    background-color: rgba(157,195,230,255);
    border: 1px solid white;  
}

.fuel-and-oil {
    background-color: rgba(189,215,238,255);
    border: 1px solid white;  
}

.maintenance {
    background-color: rgba(255,230,153,255);
    border: 1px solid white;  
}

.away-landing {
    background-color: lightgray;
    border: 1px solid white;  
}

.away-landing-light {
    background-color: darkgray;
    border: 1px solid white;  
}

