.banner {
    /* Layout */
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;

    /* Size */
    height: auto;
    min-width: 440px;
    /* min-width: fit-content; */

    /* Margin, padding and border */
    margin: 70px 5px 5px 5px;
    padding: 0% 0% 0% 0%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* border: 1px solid grey; */

    /* Colours & fonts */
    font-family: arial, helvetica, 'sans serif';
    color:rgb(113, 3, 3);
    font-weight: bold;
}

.banner .banner-row1 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    /* min-width: 400px; */
    /* width: auto; */

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.banner-row2 {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-Wrap: wrap;
    vertical-align: bottom;
    gap: 20px
}

.pilot-log-summary {
     /* Layout */

    /* Margin, padding and border */
    border: groove lightgrey 1px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    box-shadow: inset -5px -10px 25px rgb(255, 248, 220), 0px 5px 5px #333;
    margin: 10px;
    border-radius: 25px;
    max-width: 352px;


     /* Colours & fonts */

}

.pilot-selection {
    font-size: x-large;
    /* background-color: rgb(255, 248, 220); */
    background-color: rgb(254, 255, 237);
    color: rgb(0, 200, 255);
    font-weight: bold;
    min-width: 160px;
    height: 50%;
    border-color: lightgrey;
    margin: 10px 0px 10px 10px;
    padding: 0px;
    vertical-align: top;
}

.pilotLog-select {
    /* Layout */
    justify-self: flex-start;
    align-items: top;

    /* Size */
    height: auto;
    min-width: 175px;

    /* Margin, padding and border */
    margin: 5px 0px 5px 0px;
    padding: 5px 0px 5px 5x;

    /* Colours & fonts */
    /* background-color: red; */
}

.pilot-label {
    /* Layout */
    text-align: left;

    /* Size */

    /* Margins & padding */
    margin: 0px 0px 0px 0px;
    padding: 0px 0px;

    /* Colours & fonts */
    /* color: black; */
    color:black;
    /* color: black; */
    font-size: 20px;
    font-weight: normal;
}

.pilot-black-label {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    /* Size */

    /* Margins & padding */
    margin: 0px 10px 0px 10px;
    padding: 0px 0px;

    /* Colours & fonts */
    /* color: black; */
    color:black;
    /* color: black; */
    font-size: 20px;
    font-weight: bold;
}

.total-hours-chip {
    /* Layout */
    text-align: center;

    /* Size */
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;

    /* Margins & padding */
    margin: 10px 0px 5px 0px;
    padding: 0px 0px;

    /* Colours & fonts */

}

.total-hours-chip-mobile {
    /* Layout */
    text-align: left;

    /* Size */
    min-width: 345px;
    max-height: 30px;
    /* Margins & padding */
    margin: 10px 0px 10px 0px;
    padding: 0px 0px;

    /* Colours & fonts */
    color: white

}