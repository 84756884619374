/****************************** AWAY LANDINGS ******************************/
.away-landings-details {
    /* Layout */
    
    /* Size */
    max-width: 850px;
    min-width: 400px;
    height: auto;

    /* Margins & padding */
    margin: 30px 5px 0px 5px;
    padding: 10px;

    /* Colours & fonts */
    font-family: arial, helvetica, 'sans serif';
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background: white;
}

.aircraft-header {
    font-size: x-large;
    font-weight: bolder;
    width: 150px;
    height: fit-content;
    padding: 18px 5px 5px 45px;
    color: black;
    margin: 5px 5px 5px 5px;
    background-color: white;
    vertical-align: center;
}

.away-landings table {
    min-width: 360;
    max-width: 800px;
    height: auto;
    margin: 10px 10px 10px 0px;
}

.label {
    background:rgb(113, 3, 3);
    color: white;
    padding: 10px 5px 10px 5px;
    vertical-align: middle;
    min-width: 75px;
    font-weight: normal;
    border: 0px solid white;
    height: 55px;
   
}

.away-landings-title {
    font-family: arial, helvetica, 'sans serif';
    background: white;
    color: black;
    font-size: x-large;
    height: 30px;
    text-align:left;
    padding: 5px 5px 5px 5px;
    margin: 15px 0px 15px 0px;
    font-weight: bolder;
    vertical-align: middle;  
}

.label-extra-wide {
    box-sizing: border-box;
    background:rgb(113, 3, 3);
    color: white;
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    min-width: 140px;
    max-width: 600px;
    font-weight: normal;
    border: 0px solid white;
    height: auto;
}

.away-landings-image {
    background: white;
    max-height: 70px;
    max-width: 70px;
    margin:0px 0px 0px 00px;
    vertical-align: middle;
}

table, input {
    background: white;
    font-size: medium;
    width:100%;
    height:97%;
    vertical-align: middle;
}

.away-landings tbody td {
    font-family: arial, helvetica, 'sans serif';
    background: white;
    font-size: medium;
    border: 0px solid white;
    text-align: middle;
    vertical-align: middle;
    height: 40px;
    padding: 0px 0px 0px 0px;
    /* padding: 10px 10px 10px 10px;*/
}

.checkBox {
    font-family: arial, helvetica, 'sans serif';
    background: white;
    font-size: x-small;
    border: 0px solid white;
    text-align: middle;
    vertical-align: middle;
    max-width: 60px;
}

.blue-arrow {
    max-width: 60px;
    max-height: 45px;
    margin: 0px 0px 0px 240px;
    vertical-align: top;
    z-index: -4;
}

.blue-arrow-mobile {
    position: float;
    max-width: 60px;
    max-height: 45px;
    margin: 0px 0px 0px 200px;
    vertical-align: top;
    z-index: -4;
}