
/****************************** OPEN FLIGHTS  ******************************/
.openFlights {
    /* Layout */
    /* display: grid; */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    grid-template-columns: 1fr;
    grid-row-gap: 1px;
    justify-content: space-between;

    /* Size */
    /* height: auto;
    width: auto; */

    /* Margin, padding and border */
    margin: 5px 5px 5px 5px;
    padding: 0% 0% 0% 0%;
    border: 1px solid grey;

    /* Colours & fonts */
    /* background-color: rgb(255,248,220); */
    background-color: white;
    font-family: arial, helvetica, 'sans serif';

    /* font-weight: bold; */
}
.Row1 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    /* height: auto;
    width: auto; */

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.open-flights-label {
    /* Layout */
    vertical-align: center;
    position: sticky;

   /* Size */
    /* width: fit-content;
    height: auto; */

    /* Margin & padding */
    margin: 10px 0px 10px 5px;
    /* padding: 5px 5px 5px 5px; */
    border-radius: 10px;

    /* Colours and fonts */
    /* border: 1px solid grey; */
    /* background-color: rgb(245,245,245);; */
    color: black;
    /* color: rgb(0, 200, 255); */
    font-family: arial, helvetica, 'sans serif';
    font-size: large;
    gap: 15px;
    
    /* font-weight: bold; */
}

.open-flights-image {
    /* Layout */
    align-items: center;
    text-align: center;
    width: fit-content;

   /* Size */
   width: 20px;
   height: 20px;

    /* Margin & padding */
    /* border: 1px solid grey; */
    margin: 0px 10px 0px 10px;
    padding: 0px;
    align-self: center;
    vertical-align: middle;

    /* Colours and fonts */
    /* background-color: rgb(255, 248, 220); */
    /* color: lightslategray; */
    /* border: solid 2px grey; */
    font-size: large;
    font-weight: bold;
}

.open-flights-buttons-container {
    /* Layout */
    vertical-align: center;
    position: sticky;

   /* Size */
    /* width: fit-content;
    height: auto; */

    /* Margin & padding */
    /* margin: 10px 0px 10px 5px; */
    /* padding: 5px 5px 5px 5px; */
    border-radius: 10px;

    /* Colours and fonts */
    /* border: 1px solid grey; */
    /* background-color: rgb(245,245,245);; */
    color: black;
    /* color: rgb(0, 200, 255); */
    font-family: arial, helvetica, 'sans serif';
    font-size: large;
    gap: 15px;
    
    /* font-weight: bold; */
}


.open-flight-buttons {
    align-items: center;
    text-align: center;

   /* Size */
    /* width: fit-content;
    height: auto; */

    /* Margin & padding */
    margin: 0px 5px 5px 5px;
    /* padding: 2px 5px 2px 5px; */
    gap: 10px;

    /* Colours and fonts */
    border: solid 1px grey;
    background-color: 'rgb(245,245,245)';
    color: black;
    font-size: small;
    font-weight: bold;

}
