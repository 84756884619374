
.banner {
    /* Layout */
    display: grid;
    grid-template-columns: 1fr;
    /* grid-column-gap: 10px; */
    /* grid-template-rows: repeat(4, 1fr); */
    /* grid-template-rows: min-content 1f max-content min-content 1fr max-content min-content 1fr max-content min-content 1fr max-content; */
    grid-row-gap: 10px;
    /* align-self: flex-start; */
    /* box-sizing: border-box; */

    /* Size */
    height: auto;
    min-width: "385px";
    /* min-width: auto; */

    /* Margin, padding and border */
    margin: 80px 0px 10px 0px;
    /* padding: 0% 0% 0% 0%; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* border: 1px solid grey; */

    /* Colours & fonts */
    /* background-color: rgb(255,248,220); */
    /* background-color: rgb(245, 245, 235); */
    /* background-color: rgb(255, 253, 231); */
    /* background-color: rgb(255,255,240); */
    /* background-color: rgb(254, 255, 237); */
    /* background-color: black; */
    font-family: arial, helvetica, 'sans serif';
    /* color:rgb(113, 3, 3); */
    color:rgb(78, 77, 77);
    font-weight: bold;
}

/* ************************************** ROW 1 ********************************************** */
.banner .bannerRow1 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    min-width: "385px";
    /* width: auto; */

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.title-desktop {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    margin: 10px, 3px, 10px, 10px;
    /* padding: 10px, 3px, 10px, 10px; */

     /* Colours & fonts */
    color:rgb(113, 3, 3);
    font-size: 60px;
    text-align: left;
}

.title-mobile {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    margin: 10px, 0px, 10px, 10px;

     /* Colours & fonts */
    color:rgb(113, 3, 3);
    font-size: 45px;
    text-align: left;
}

.banner .logo {
    /* Cell C:1 of Banner Grid */
    justify-self: end;
    align-items: top;

    /* Size */
    height: auto;
    width: auto;
}

.databaseConnectionWarning {
    /* Layout */
    /* text-align: left; */
    display: flex;
    flex-direction: row;
    justify-self: flex-end;

    /* Size */
    width: fit-content;

    /* Margins & padding */
    margin: 0px 0px 10px 25px;
    padding: 0px 0px;

    /* Colours & fonts */

}

/* ************************************** ROW 2 ********************************************** */
.banner .bannerRow2 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;
    gap: "69px";

    /* Size */
    height: auto;
    min-width: "385px";
    /* width: auto; */

    /* Margin, padding and border */
    margin: 0px 0px 0px 5px;
    padding: 0px;

    /* Colours & fonts */
    /* background-color: lightblue; */
}

.banner .banner-aircraft-details {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: flex-start;
    gap: 50px;

    /* Size */
    height: auto;
    width: fit-content;

    /* Margin, padding and border */
    margin: 0px 0px 0px 5px;
    padding: 0px;

    /* Colours & fonts */
    /* background-color: lightblue; */
}

.banner .aircraftSelect {
    /* Layout */
    justify-self: flex-start;
    align-items: top;

    /* Size */
    height: auto;
    min-width: 175px;

    /* Margin, padding and border */
    margin: 5px 0px 5px 0px;
    padding: 5px 0px 5px 5x;

    /* Colours & fonts */
    /* background-color: red; */
}

.banner .aircraftSelectMobile {
    /* Layout */
    justify-self: flex-start;
    align-items: center;

    /* Size */
    height: auto;
    min-width: 160px;

    /* Margin, padding and border */
    margin: 5px 10px 5px 0px;
    padding: 5px 0px 15px 0x;

    /* Colours & fonts */

}

.aircraft-selection {
    font-size: x-large;
    /* background-color: rgb(255, 248, 220); */
    background-color: rgb(254, 255, 237);
    color: rgb(0, 200, 255);
    font-weight: bold;
    min-width: 160px;
    height: 50%;
    border-color: lightgrey;
    margin: 15px 0px 0px 0px;
    padding: 0px;
    vertical-align: top;
}

.banner .makeAndModel {
    /* Layout */
    justify-self: center;
    align-items: center;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 04px 0px 0px 0px;
    padding: 2px 0px 0px 0px;

    /* Colours & fonts */
    font-size: xx-large;
    /* background-color: blue; */
}

.banner .makeAndModelMobile {
    /* Layout */
    justify-self: center;
    align-items: center;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 20px 5px 20px;
    padding: 0px 0px 5px 0px;


    /* Colours & fonts */
    font-size: large;
}

.aircraft-label {
    /* Layout */
    text-align: left;

    /* Size */

    /* Margins & padding */
    margin: 0px 10px 14px 0px;
    padding: 0px 0px;

    /* Colours & fonts */
    /* color: black; */
    /* color:rgb(113, 3, 3); */
    /* color:rgb(78, 77, 77); */
    color:black;
    /* color: black; */
    font-size: 20px;
    font-weight: normal;
}

.admin-aircraft-subtitles-2 {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-evenly;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 5px 10px;

    /* Colours & Fonts */
    font-size: small;
    /* background-color: white; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.maintenance-chips-banner {
    /* Layout */
    text-align: left;

    /* Size */

    /* Margins & padding */
    margin: 0px 10px 5px 0px;
    padding: 0px 0px;


    /* Colours & fonts */
    /* color: black; */
    color:black;
    /* color: black; */
    font-size: 20px;
    font-weight: normal;
    /* background-color: white; */
}

.aircraft-type {
    /* Layout */
    vertical-align: middle;
    width: fit-content;

   /* Size */
    width: auto;
    height: auto;

    /* Margin & padding */
    margin: 23px 5px 10px 0px;
    padding: 12px 5px 0px 0px;

    /* Colours and fonts */
    /* background-color: rgb(255, 248, 220); */
    color: black;
    font-size: x-large;
    font-weight: bold;
}

.aircraft-type-mobile {
    /* Layout */
    vertical-align: middle;
    width: fit-content;

   /* Size */
    width: auto;
    height: auto;

    /* Margin & padding */
    margin: 35px 5px 0px 0px;
    padding: 0px 0px 0px 0px;

    /* Colours and fonts */
    /* background-color: rgb(255, 248, 220); */
    color: black;
    font-size: large;
    font-weight: bold;
}

.banner-clear-button {
    /* Layout */
    /* display: flex;
    flex-Direction: row;
    justify-self: flex-end;
    flex-Wrap: wrap;
    vertical-align: bottom; */
    align-items: right;
    vertical-align: bottom;

    /* Size */
    /* max-height: 30px; */
    /* max-width: auto; */
    /* max-width: 90px; */
    /* max-width: 50px; */
    /* max-width: fit-content; */

    /* Margin, padding and border */
    margin: 95px 10px 0px 0px;
    padding: 0px 0px 0px 0px;
    /* Colours & fonts */
    /* color: black; */
    /* background-color: black; */
}

.banner-clear-button-mobile {
    /* Layout */
    /* display: flex;
    flex-Direction: row;
    justify-self: end;
    flex-Wrap: wrap; */
    align-items: right;

    /* Size */
    /* max-height: 30px; */
   

    /* Margin, padding and border */
    margin: 0px 10px 10px 300px;
    padding: 0px;

    /* Colours & fonts */
    /* color: black;
    background-color: black; */
}

.banner .switch {
    /* Layout */
    margin: 70px 10px 5px 32px;
    align-items: center;

    /* Borders, Padding & Margins */

    /* Colours and Fonts */

}

.banner .switch-mobile {
    /* Layout */
    margin: 70px 10px 5px 32px;
    /* Borders, Padding & Margins */

    /* Colours and Fonts */

}

/* ************************************** ROW 3 ********************************************** */
.banner .bannerRow3 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 10px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.maintenance-title {
    /* Layout */

    /* Margins & padding */
    margin: 0px 0px 10px 5px;
    padding: 0px 0px;
    justify-self: start;
    align-items: top;

    /* Colours & fonts */
    /* font: black; */
    /* color:rgb(113, 3, 3); */
    color:rgb(78, 77, 77);
    font-size: 20px;
    font-weight: normal;
}

.banner .maintenanceTable1 {
    /* Layout */

    /* Size */
    height: auto;
    width: 350px;

    /* Margin, padding and border */
    margin: 0px 0px 0px 10px;
    padding: 0px 0px 0px 0px;

    /* Colours & fonts */
}

.banner .maintenanceTable2 {
    /* Layout */
    
    /* Size */
    height: auto;
    width: 350px;

    /* Margin, padding and border */
    margin: 0px 0px 0px 10px;
    padding: 0px;

    /* Colours & fonts */
}

.banner .maintenanceTable3 {
    /* Layout */

    /* Size */
    height: auto;
    width: 350px;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.aircraft-label2 {
    /* background-color: rgb(255,248,220); */
    background-color: rgb(254, 255, 237);
    /* background-color: rgb(254, 255, 237); */
    /* background-color:  black; */
    /* border: rgb(255, 248, 220); */
    font-size: small;
    color: black;
    font-weight: normal;
    font-style: italic;
    margin: 0px 5px;
    padding: 0px 0 5px 2px;
}

.aircraft-data h2 {
    margin: 2% 0% 0% 0%;
    /* color:rgb(113, 3, 3); */
    color:rgb(78, 77, 77);
    background: rgb(255, 248, 220);
    text-align: left;
    font-size: 20px;
    font-weight: normal;
    vertical-align: middle;
    padding: 10px;
}

.aircraft-data {
    margin: 0% 0% 0% 0%;
    background:rgb(255, 248, 220);
    color: white;
    text-align:left;
    max-height: 150px;
    align-items: left;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    vertical-align: middle;
    font-size: 20px;
    font-weight: normal;
}

.maintenance-header {
    /* color: white;*/
    text-align: center;
    background: rgb(113, 3, 3);
    border: 1px solid white;
    font: white;
    font-size: 20px;
    font-weight: normal;
    height: 40px;
    padding: 5px;
    min-height: 200px;
    vertical-align: middle;
}

.maintenance-header-2 {
    font-size: medium;
    font-weight: normal;
    background: black;
    border: 1px solid white;
    padding: 4px;
    text-align: center;
    height:50px;
    color: white;
    /* height:fit-content;*/
    vertical-align: middle;
}

.maintenance-data {
    font-size: medium;
    font-weight: normal;
    background: rgb(224, 224, 224);
    text-align: center;
    color:black;
    font-weight: bold;
    border: 1px solid white;
    vertical-align: middle;
    padding: 10px;
    height:50px;
}

.maintenance-data-GREEN {
    font-size: medium;
    font-weight: normal;
    background: rgb(35, 155, 86);
    text-align: center;
    color:white;
    font-weight: bold;
    border: 1px solid white;
    vertical-align: middle;
    padding: 10px;
    height:50px;
}

.maintenance-data-AMBER {
    font-size: medium;
    font-weight: normal;
    background: rgb(249, 231, 159);
    text-align: center;
    color:black;
    font-weight: bold;
    border: 1px solid white;
    vertical-align: middle;
    padding: 10px;
    height:50px;
}

.maintenance-data-RED {
    font-size: medium;
    font-weight: normal;
    background: rgb(213, 0, 0);
    text-align: center;
    color:white;
    font-weight: bold;
    border: 1px solid white;
    vertical-align: middle;
    padding: 10px;
    height:50px;
}

.maintenance-defects-frame {
    /* Layout */
     vertical-align: middle;
     text-align:left;
     align-items: left;
     
     /* Size */
     max-height: 150px;
     min-Height: 89px;
     min-width: fit-content;

    /* Borders & Margins */
     margin: 8px 0px 10px 5px;
     padding: 0px 2px 0px 0px;
     border: groove lightgrey 1px;
     border-radius: 20px;
     box-shadow: inset -5px -10px 25px rgb(255, 248, 220), 0px 5px 5px #333;
     /* box-shadow: inset 2px 5px 20px #555; */

     /* margin: 0px 10px 14px 0px;
     padding: 0px 0px; */
    
     /* Fonts and colours */
     /* background-color:rgb(255, 248, 220); */
     background-color: rgb(254, 255, 237);
     color: black;
     
     font-size: 20px;
     font-weight: normal;
 }

.maintenance-defects {
    font-size: medium;
    font-weight: normal;
    background:rgb(255, 248, 220);
    /* background: rgb(224, 224, 224); */
    /* background: white; */
    text-align: left;
    color:black;
    font-weight: bold;
    border: 1px solid white;
    vertical-align: middle;
    padding: 5px;
    width: auto;
}

.blue-arrow-defects {
    position: float;
    max-width: 20px;
    max-height: 25px;
    margin: 5px 0px 0px 10px;
    vertical-align: top;
    z-index: -4;
}

/* ************************************** ROW 4 ********************************************** */


/* .banner .button { */
    /* background: rgb(255, 248, 220); */
    /* background: rgb(21, 101, 192); */
    /* margin: 0px 0px 10px 250px; */
    /* width: 90px; */
/* } */

.banner .snackBar {
    /* background: rgb(255, 248, 220); */
    /* background: rgb(21, 101, 192); */
    margin: 0px 0px 5px 5px;
    width: 200px;
}

