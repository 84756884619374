/****************************** ARRIVAL - Main ******************************/

.arrival {
    /* Layout */
    /* grid-area: "arrival"; */
        display: grid;
        grid-template-areas:
        "arr-image arr-details";
        grid-template-columns: 220px 1fr;

    /* Size */
    width: auto;
    height: auto;

    /* Margin & Padding */
    margin: 0px 0px 1px 0px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */

    /* Colours & Font */
    font-family: arial, helvetica, 'sans serif';
    background-color: white;
}

.arrival-mobile {
    /* Layout */
    z-index: 0;
    grid-area: "arrival";
        display: grid;
        grid-template-areas:
        "arr-image"
        "arr-details";
        grid-template-rows: 8% 92%;

    /* Size */
    width: auto;
    height: auto;

    /* Margin & Padding */
    margin: 0px 0px 0px 0px;

    /* Colours & Font */
    font-family: arial, helvetica, 'sans serif';
    background-color: white;
}

/****************************** ARRIVAL - Image ******************************/

.arr-image {
    /* Layout */
    grid-area: "arr-image";

    /* Size */
    height: auto;
    width: auto;

    /* Borders, margins & padding */
    margin: 0px 10px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px;

    /* Colours & fonts */
    color:rgb(113, 3, 3);
    font-size: xx-large;
    /* background-color: RGB(255,248,220); */
    background-color: rgb(254, 255, 237);
    vertical-align: top;
}

.arr-image-mobile {
    /* Layout */
    z-index: 4;
    position: sticky;
    top: 60px;

    /* Size */
    width: auto;
    min-height: 80px;
    max-height: 80px;

    /* Borders, margins & padding */
    margin: 0px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px 0px 0px 0px;

    /* Colours & fonts */
    color:rgb(113, 3, 3);
    font-size: x-large;
    /* background-color: RGB(255,248,220); */
    background-color: rgb(254, 255, 237);
    vertical-align: top;

}

.arr-image .arr-title {
    background-color: rgb(254, 255, 237);
    /* background-color: RGB(255,248,220); */
    margin: 10px 10px 0px 10px;
    padding: 5px;
}

.arr-image-mobile .arr-title-mobile {
    background-color: rgb(254, 255, 237);
    /* background-color: RGB(255,248,220); */
    margin: 25px 10px 0px 10px;
    padding: 5px;
}

.arrival .arr-image img {
    max-width:70%;
    background-color: rgb(254, 255, 237);
    /* background-color: RGB(255,248,220); */
    vertical-align: middle;
    padding: 0px 0px 0px 10px;
    max-height: 190px;
    min-width: 170px;
    align-items: left;
}

.arrival-mobile .arr-image-mobile img {
    /* background-color: RGB(255,248,220); */
    background-color: rgb(254, 255, 237);
    max-width: 13%;
    margin: 0px 10px 0px 40px
}

/****************************** ARRIVAL - Details ******************************/

.arrival .arr-details {
    /* Layout */
    grid-area: arr-details;
        display: grid;
        grid-template-rows: [row1] 0.1fr [row2] 0.4fr [row3] 0.4fr [row4] 0.1fr;
       
    /* Sizing */
    width: auto;
    height: auto;

    /* Margins & padding */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px;
    margin:  0px 5px 0px 0px;

    /* Colours & Fonts   */
    font-family: arial, helvetica, 'sans serif';
    background: white;
}

.arrival-mobile .arr-details-mobile {
    /* Layout */
    grid-area: "arr-details";

    /* Sizing */
    width: auto;
    height: fit-content;

    /* Margins & padding */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px;
    margin: 0px 0px 0px 0px;
   
    /* Colours & Fonts   */
    background-color: white; /* RGB(255,248,220);*/
}

.aircraft-header {
    font-size: x-large;
    font-weight: bolder;
    min-width: 180px;
    height: fit-content;
    padding: 5px 5px 5px 5px;
    color: black;
    margin: 10px 10px 10px 10px;
    background-color: White;
    vertical-align: center;
}

.aircraft-header-mobile {
    font-size: x-large;
    font-weight: bolder;
    min-width: 140px;
    height: fit-content;
    padding: 20px 5px 5px 10px;
    color: black;
    margin: 10px 10px 10px 10px;
    background-color: rgb(254, 255, 237);
    /* background-color: RGB(255,248,220); */
    vertical-align: center;
}

/****************************** ARRIVAL - Details Rows 2 and 3 ******************************/

.arr-details-row2 {
    /* Layout */
        display: flex;
        flex-Direction: row;
        flex-Wrap: wrap;
        justify-content: flex-start;
        vertical-align: bottom;
        gap: 10px;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
    /* background-color: grey; */
    
}

.arr-details-row3 {
    /* Layout */
    /* grid-area: "dep-details-row3"; */
        display: flex;
        flex-Direction: row;
        flex-Wrap: wrap;
        justify-content: flex-start;
        gap: 0px;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
    
}

.LR {
    background: white;
    text-align: left;
    padding: 3px 0 0px 5px;
    width:40px;
    height: 20px;
    vertical-align: bottom;
    font-weight: bold;
}

.arrival-table1-navigation {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    min-width: 310px;
    max-width: 310px;

    /* Margins & borders */
    margin: 10px 0px 10px 5px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
}

.arrival-table2-navigation {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    min-width: 310px;
    max-width: 310px;

    /* Margins & borders */
    margin: 13px 0px 10px 5px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
}

.arrival-table2-navigation-wide {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    min-width: 350px;
    max-width: 350px;

    /* Margins & borders */
    margin: 0px 0px 10px 5px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
}

.arrival-table3-navigation {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    min-width: 310px;
    max-width: 310px;

    /* Margins & borders */
    margin: 27px 0px 10px 10px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
}

.arrival-table-fuel {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    max-width: 450px;

    /* Margins & borders */
    margin: 2px 20px 10px 5px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
}

.arrival-table-times {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    max-width: 388px;

    /* Margins & borders */
    margin: 0px 0px 0px 5px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
}

.arrival-table-times-wide {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    max-width: 700px;

    /* Margins & borders */
    margin: 14px 0px 0px 10px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
    /* background-color: lightgrey; */
}

.arrival-table-defects {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    min-width: 375px;
    max-width: 375px;
    max-height: 400px;

    /* Margins & borders */
    margin: 69px 10px 0px 5px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
    /* background-color: lightgrey; */
}

.arrival-table-defects-mobile {
    /* Layout */
    background: white;
    text-align: left;
    vertical-align: middle;

    /* Size */
    min-width: 391px;
    max-width: 391px;
    max-height: 400px;

    /* Margins & borders */
    margin: 20px 10px 0px 5px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
    background-color: lightgrey;
}

.arrival-table2-defects {
    /* Layout */
    background: grey;
    text-align: left;
    vertical-align: middle;

    /* Size */
    min-width: 388px;
    max-width: 388px;
    max-height: 400px;

    /* Margins & borders */
    margin: 60px 0px 0px 0px;
    border: 0px solid white;

    /* Fonts & colours */
    font-size: small;
}

.blank {
    background: whtie;
    border: 0px solid white;
    width: 10px;
    height: 55px;
}

.grey {
    background: lightgrey;
    border: 0px solid white;
    width: 10px;
    height: 55px;
}

.blankALT {
    background: white;
    border: 0px solid white;
    width: 10px;
    height:55px
}

.arr-table-header{
    line-height: 4px;
    background:lightcyan;
}

.label {
    background:rgb(113, 3, 3);
    color: white;
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    font-weight: normal;
    border: 0px solid white;
    min-height: 57px;
    min-width: 90px;
    max-width: 90px;
}

.labelLight {
    box-sizing: border-box;
    background:rgb(197, 193, 190);
    color: black;
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    min-width: 70px;
    font-weight: normal;
    border: 0px solid white;
    height: 55px;
    width: 60px;
}

.calc {
    box-sizing: border-box;
    font-style: bold;
    color: white;
    background: black;
    font-size: small;
    font-weight: normal;
    padding: 10px 0 0 0px;
    vertical-align: middle;
    text-align: center;
    border: 0px solid white;
    max-height: 65px;
    max-width: 65px;
}

.label-long {
    box-sizing: border-box;
    background:rgb(113, 3, 3);
    color: white;
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    min-width: 150px;
    font-weight: normal;
    border: 0px solid white;
    height: auto;
}

.label-extra-long {
    box-sizing: border-box;
    background:rgb(113, 3, 3);
    color: white;
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    min-width: 250px;
    font-weight: normal;
    border: 0px solid white;
    height: 57px;
}

.input {
    background: white;
    text-align: left;
    vertical-align: middle;
    max-width: 100px;
    height:100%;
    border: 0px solid white;
}

.inputError {
    /* background: white; */
    background: rgb(252,252,88);
    text-align: left;
    vertical-align: middle;
    width: 150px;
    height: 100%;
    border: 0px solid white;
}

.inputNum {
    background: white;
    /* padding: 10px 5px 10px 10px;*/
    vertical-align: middle;
    border: 0px solid white;
    min-width: 75px;
    height: 40px;
}

.map {
    background-color: white;
    max-width: 50px;
    max-height: 50px;
    vertical-align: top;
    margin: 5px 5px 5px 0px;
}

.clock {
    background-color: white;
    max-width: 50px;
    max-height: 50px;
    vertical-align: top;
    padding: 5px;

}

.petrolImg {
    background: grey;
    height: 30px;
    width: 40px;
    align-items: center;
    vertical-align: bottom;
    padding: 0px 0px 0px 0;
}

.shortArrow {
    width: 20px;
    height: 20px;
    margin: 20px 0 0 5px;
    align-self: center;
}

.shortArrow2 {
    width: 20px;
    height: 20px;
    margin: 0px 0px 15px 15px; 
}

.longArrow {
    width: 560px;
    height: 35px;
    margin: 0px 0 0 20px;
    background-color: grey;
}

.blue-arrow {
    position: float;
    max-width: 60px;
    max-height: 45px;
    margin: 0px 0px 0px 240px;
    vertical-align: top;
    z-index: -4;
}

.blue-arrow-mobile {
    position: float;
    max-width: 60px;
    max-height: 45px;
    margin: 0px 0px 0px 20px;
    vertical-align: top;
    z-index: -4;
}

.footnote {
    font-style: normal;
    color: white;
    font-size: x-small;
    font-weight: normal;
    padding: 10px 0 0 0px;
}

/****************************** ARRIVAL - Row 4 Button ******************************/

.arr-details-row4 {
    /* Layout */
    /* grid-area: "dep-details-row3"; */
        display: flex;
        flex-Direction: row;
        flex-Wrap: wrap;
        justify-content: flex-end;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
    
}

.arr-details-row4 .buttonSubmit {
    /* background: rgb(255, 248, 220); */
    background: darkgreen;
    margin: 10px 10px 10px 0px;
    width: 90px;
    align-items: right;
}

/****************************** COMMON ******************************/



.footnote {
    font-style: normal;
    color: white;
    font-size: x-small;
    font-weight: normal;
    padding: 10px 0 0 0px;
}

.note {
    font-style: italic;
    color: black;
    background-color: white;
    font-size: small;
    font-weight: normal;
    width: auto;
    padding: 10px 0px 0px 0px;
    vertical-align: center;
    align-items: center;
}
.noteBlue {
    font-style: italic;
    color: rgb(0, 200, 255);
    /* color: rgb(0, 200, 255); */
    background-color: white;
    font-size: medium;
    font-weight: normal;
    width: auto;
    padding: 20px 0px 0px 10px;
    vertical-align: middle;
    text-align: center;
}

.noteError {
    font-style: italic;
    color: red;
    background-color: white;
    font-size: small;
    font-weight: normal;
    width: auto;
    padding: 5px 0px 10px 20px;
    vertical-align: middle;
    text-align: center;
}

.noteSuccess {
    font-style: italic;
    color: green;
    /* color: rgb(0, 200, 255); */
    background-color: white;
    font-size: small;
    font-weight: normal;
    width: auto;
    padding: 10px 0px 10px 20px;
    vertical-align: middle;
    text-align: center;
    align-items: center;
}