/* *********************************** ADMIN AIRCRAFT BANNER *************************************** */

.banner {
    /* Layout */
    display: grid;
    /* grid-template-columns: 3fr; */
    /* /* z-index: 4; */
    /* position: fixed; */
    top: 0px;
    grid-row-gap: 5px;
    /* align-self: flex-start; */
    /* box-sizing: border-box; */

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 75px 0px 10px 0px;
    /* padding: 0% 0% 0% 0%; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* border: 1px solid grey; */

    /* Colours & fonts */
    background-color: rgb(255,248,220);
    font-family: arial, helvetica, 'sans serif';
    color:rgb(113, 3, 3);
    font-weight: bold;
}

/* ************************************** BANNER ROW 1 ********************************************** */
.banner .bannerRow1 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.banner .titleDesktop {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    padding: 10px;

     /* Colours & fonts */
    font-size: 70px;
    text-align: left;
}

.banner .titleMobile {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    padding: 10px 0px 10px 10px;

     /* Colours & fonts */
    font-size: 40px;
    text-align: left;
}

.banner .logo {
    /* Cell C:1 of Banner Grid */
    justify-self: end;
    align-items: top;

    /* Size */
    height: auto;
    width: auto;
}

/* ************************************** BANNER ROW 2 ********************************************** */
.bannerRowAdmin {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
    background-color: inherit
}

.buttonSubmit {
    /* background: rgb(255, 248, 220); */
    background: darkgreen;
    margin: 10px 0px 05px 0px;
    width: 80px;
    align-items: right;
}

.switch-box {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    /* Borders, Padding & Margins */
    margin: 70px 10px 5px 32px;

    /* Colours and Fonts */
    background-color: inherit
}

.switch-box-mobile {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;

    /* Borders, Padding & Margins */
    margin: 0px 0px 10px 10px;


    /* Colours and Fonts */
    background-color: inherit
}

.switch {
    /* Layout */
    margin: 0px 5px 5px 5px;
    /* align-items: center; */

    /* Borders, Padding & Margins */

    /* Colours and Fonts */
    background-color: inherit
}

.banner .aircraftSelectAdmin {
    /* Layout */
    justify-self: start;
    align-items: top;

    /* Size */
    height: auto;
    min-width: 175px;

    /* Margin, padding and border */
    margin: 0px 5px 5px 5px;
    padding: 10px 0px 10px 5px;

    /* Colours & fonts */
}

.aircraft-selection {
    font-size: x-large;
    background-color: rgb(255, 248, 220);
    color: rgb(0, 200, 255);
    font-weight: bold;
    margin: 15px 15px 15px 0px;
    min-width: 150px;
    height: 50%;
    border-color: lightgrey;
    padding: 0px 5px 5px 5px;
    vertical-align: top;
}

.aircraft-label2 {
    /* background-color: rgb(255,248,220); */
    /* border: rgb(255, 248, 220); */
    align-items: center;
    font-size: x-small;
    color: black;
    font-weight: normal;
    font-style: italic;
    margin: 0px 5px;
    padding: 0px 0 5px 27px;
}

/* *********************************** ADMIN AIRCRAFT MAIN FIELDS *************************************** */
.admin-aircraft-frame {
    /* Layout */
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;

    /* size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Borders, Margins & Padding */
    margin: 5px 0px, 5px 0px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    /* Colours & Fonts */
    background-color: white;
}

.admin-aircraft-frame-mobile {
    /* Layout */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    /* size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Borders, Margins & Padding */
    margin: 5px 0px, 5px 0px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    /* Colours & Fonts */
    background-color: white;
}

.admin-aircraft-fields-container {
    /* Layout */
    grid-column: 1 / 2;
    display: grid;
    grid-template-rows: repeat (3, 1fr);
    grid-template-columns: repeat (4, 1fr);
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
   

    /* Colours & Fonts */
    /* background-color: white; */

}

.admin-aircraft-subtitles {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-between;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;

    /* Colours & Fonts */
    font-size: large;
    /* background-color: white; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-subtitles-2 {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-evenly;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 5px 10px;

    /* Colours & Fonts */
    font-size: small;
    /* background-color: white; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-subtitles-banner {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-evenly;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 0px 10px;

    /* Colours & Fonts */
    font-size: small;
    /* background-color: white; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-fields {
    /* Layout */
    grid-row: 2;
    grid-column: 1 / -2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;;
    gap: 25px;

    /* size */
    /* height: auto; */
    min-height: 40px;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-fields-2 {
    /* Layout */
    grid-row: 3;
    grid-column: 1 / -2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;;
    gap: 25px;

    /* size */
    height: auto;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-fields-3 {
    /* Layout */
    grid-row: 4;
    grid-column: 1 / -2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 25px;

    /* size */
    height: auto;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-field {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 200px;
    min-width: 200px;
    min-height: 40px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-date-field {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 200px;
    min-width: 200px;
    min-height: 40px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-select-field {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 200px;
    min-width: 200px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    /* margin: 0px 5px 5px 5px; */
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-aircraft-field-display {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 150px;
    min-width: 150px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 10px 10px 10px;
    border: solid 1px lightgrey;
    border-radius: 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-size: small;
    font-family: arial;
    word-wrap: normal;
    background-color: green;
}

.admin-aircraft-field-small {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 100px;
    min-width: 100px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.select-options {
    /* background: rgb(245,245,245); */
    /* background-color: white; */
    text-align: middle;
    vertical-align: bottom;
    /* width: 100%;
    height: 100%; */
    min-width: 75px;
    height: 57px;
    font-family: Arial, Helvetica, sans-serif;
}

/* *********************************** ADMIN AIRCRAFT DEFECTS *************************************** */

.admin-aircraft-defects-container {
    /* Layout */
    grid-column: 2/3;
    grid-row: 1;
    
    /* grid-template-columns: repeat (4, 1fr); */
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
   

    /* Colours & Fonts */
    background-color: white;
}

.admin-aircraft-defects-container-mobile {
    /* Layout */
    grid-column: 1;
    
    /* grid-row: 1; */
    /* grid-template-columns: repeat (4, 1fr); */
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
   

    /* Colours & Fonts */
    background-color: white;
}

