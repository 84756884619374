.homePage {
    border: 1px solid gray;
    background-image: url("./images/homePage.png");
    object-fit: fill;
    height: 1100px;
    width: auto;
    padding: 0% 0% 0% 0%;
    margin: 80px 10px 10px 10px;
    font-family: arial, helvetica, 'sans serif';
    color:rgb(113, 3, 3);
    font-weight: bold;
}

.container {
    margin: 8% 0 0 15%;
    padding:10px 10px 10px 10px;
    width: 30%;
    /* border: 10px solid black; */
    text-align: center;
}

.title1 {
    /* margin: 15%; */
    /* font-family: arial(sans-serif); */
    font-family: Helvetica, sans-serif;
    font-family: arial(sans-serif);
    font-family: arial(sans-serif);
    font-family: arial(sans-serif);
    font-weight: bold;
    font-size: xx-large;
    text-align: middle;
}

.title2 {
    /* margin: 30% 10% 10% 10%; */
    z-index: 1.1;
    /* font-family: Arial; */
    font-family: arial(sans-serif);
    /* font-family: arial(sans-serif);
    font-family: arial(sans-serif);
    font-family: arial(sans-serif); */
    font-size: x-large;
    color: black;
    text-decoration-line: underline;
    text-align: middle;
}