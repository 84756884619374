/* *********************************** ADMIN PERSON BANNER *************************************** */

.banner {
    /* Layout */
    display: grid;
    /* grid-template-columns: 3fr; */
    /* /* z-index: 4; */
    /* position: fixed; */
    top: 0px;
    grid-row-gap: 5px;
    /* align-self: flex-start; */
    /* box-sizing: border-box; */

    /* Size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Margin, padding and border */
    margin: 75px 0px 10px 0px;
    padding: 0% 0% 0% 0%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* border: 1px solid grey; */

    /* Colours & fonts */
    background-color: rgb(254, 255, 237);
    font-family: arial, helvetica, 'sans serif';
    color:rgb(113, 3, 3);
    font-weight: bold;
}

/* ************************************** BANNER ROW 1 ********************************************** */
.banner .bannerRow1 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.banner .titleDesktop {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;
    

    /* Margin, padding and border */
    padding: 10px;

     /* Colours & fonts */
    font-size: 70px;
    text-align: left;
}

.banner .titleMobile {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    padding: 10px 0px 10px 10px;

     /* Colours & fonts */
    font-size: 40px;
    text-align: left;
}

.banner .logo {
    /* Cell C:1 of Banner Grid */
    justify-self: end;
    align-items: top;

    /* Size */
    height: auto;
    width: auto;
}

/* ************************************** BANNER ROW 2 ********************************************** */
.banner .bannerRowAdmin {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    width: auto;
    min-width: 350px;

    /* Margin, padding and border */
    margin: 0px 10px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.banner .switch {
    /* Layout */
    margin: 70px 10px 0px 32px;
    align-items: center;
    vertical-align: bottom;

    /* Borders, Padding & Margins */

    /* Colours and Fonts */
    background-color: inherit;

}

.banner .switch-mobile {
    /* Layout */
    margin: 10px 0px 0px 0px;
    /* Borders, Padding & Margins */

    /* Colours and Fonts */

}   

.banner .personSelectAdmin {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: bottom;

    /* Size */
    height: auto;
    min-width: 175px;

    /* Margin, padding and border */
    margin: 10px 10px 10px 10px;
    padding: 0px 0px 0px 0px;

    /* Colours & fonts */
}

.admin-selection {
    font-size: x-large;
    background-color: rgb(255, 248, 220);
    color: rgb(0, 200, 255);
    font-weight: bold;
    margin: 15px 15px 15px 0px;
    min-width: 150px;
    height: 50%;
    border-color: lightgrey;
    padding: 0px 5px 5px 5px;
    vertical-align: top;
}

.person-label-banner {
    /* Layout */
    text-align: left;

    /* Size */

    /* Margins & padding */
    margin: 0px 10px 20px 0px;
    padding: 0px 0px;

    /* Colours & fonts */
    /* color:   rgb(0, 200, 255); */
    color: black;
    font-size: 20px;
    font-weight: bold;

}

.admin-subtitles-banner {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-evenly;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 0px 10px;

    /* Colours & Fonts */
    font-size: small;
    /* background-color: white; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.select-options {
    /* background: rgb(245,245,245); */
    /* background-color: white; */
    text-align: middle;
    vertical-align: bottom;
    /* width: 100%;
    height: 100%; */
    min-width: 75px;
    height: 57px;
    font-family: Arial, Helvetica, sans-serif;
}

/* *********************************** ADMIN PERSON  *************************************** */
.admin-frame {
    /* Layout */
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr;

    /* size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Borders, Margins & Padding */
    margin: 5px 0px, 5px 0px;
    padding: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    /* Colours & Fonts */
    background-color: white;
}


/* *********************************** ADMIN PERSON ROLES *************************************** */


.admin-fields-container {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
   
    /* Colours & Fonts */
    /* background-color: white; */

}

.admin-fields-container-empty {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
   
    /* Colours & Fonts */
    /* background-color: white; */

}

.admin-subtitles-roles {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: flex-start;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 10px 1  0px 5px;

    /* Colours & Fonts */
    font-size: large;
    /* background-color: white; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-checkboxes {
    /* Layout */
    /* grid-row: 2;
    grid-column: 1 / -2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;;
    gap: 15px;

    /* size */
    /* height: auto; */
    min-height: 40px;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.admin-checkboxes-empty {
    /* Layout */
    /* grid-row: 2;
    grid-column: 1 / -2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    vertical-align: top;
    /* gap:    5px; */

    /* size */
    /* height: auto; */
    min-height: 40px;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    color: "gray";
    font-size: 15px;
    font-weight: normal;
    font-family: arial;
    word-wrap: normal;
}

/* *********************************** ADMIN NEW PERSON *************************************** */

.admin-new-person-container {
    /* Layout */
    grid-column: 2/3;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    /* grid-template-columns: repeat (4, 1fr); */
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;
   

    /* Colours & Fonts */
    background-color: white;
}

.admin-new-person-container-mobile {
    /* Layout */
    grid-column: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    /* grid-row: 1; */
    /* grid-template-columns: repeat (4, 1fr); */
    
    /* size */
    height: auto;
    min-width: 350px;

    /* Borders, Margins & Padding */
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    /* padding: 5px 5px 5px 5px; */
    margin: 10px 10px 10px 10px;
    padding: 10px 10px 10px 10px;

    /* Colours & Fonts */
    background-color: white;
}

.admin-subtitles-new {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-between;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 0px 5px 0px 0px;

    /* Colours & Fonts */
    font-size: large;
    /* background-color: white; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

/* *********************************** ADMIN PERSON BUTTONS *************************************** */
.button {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    vertical-align: bottom;

    /* Borders, Padding & Margins */
    /* margin: 10px 10px 10px 10px; */
    margin: 0px 0px 0px 0px
    /* margin: 10px */

    /* Colours and Fonts */

}

.button-mobile {
    /* Layout*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    vertical-align: bottom;
   
    /* Borders, Padding & Margins */
    margin: 0px 0px 0px 0px;

    /* Colours and Fonts */
    
}   

