/* *********************************** ADMIN AIRCRAFT BANNER *************************************** */

.banner {
    /* Layout */
    display: grid;
    /* grid-template-columns: 3fr; */
    /* /* z-index: 4; */
    /* position: fixed; */
    top: 0px;
    grid-row-gap: 5px;
    /* align-self: flex-start; */
    /* box-sizing: border-box; */

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 75px 0px 10px 0px;
    padding: 0% 0% 0% 0%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* border: 1px solid grey; */

    /* Colours & fonts */
    background-color: rgb(255,248,220);
    font-family: arial, helvetica, 'sans serif';
    color:rgb(113, 3, 3);
    font-weight: bold;
}

/* ************************************** BANNER ROW 1 ********************************************** */
.bannerRow1 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: nowrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    width: auto;
    /* max-width: 355px; */

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.titleDesktop {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    padding: 10px;

     /* Colours & fonts */
    font-size: 60px;
    text-align: left;
}

.titleMobile {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;
    max-width: 325px;

    /* Margin, padding and border */
    padding: 10px 0px 10px 10px;

     /* Colours & fonts */
    font-size: 40px;
    text-align: left;
}

.logo {
    /* Cell C:1 of Banner Grid */
    justify-self: end;
    align-items: top;

    /* Size */
    height: auto;
    width: auto;
}

/* ************************************** BANNER ROW 2 ********************************************** */
.banner-row {
    /* Layout */
    display: flex;
    flex-Direction: row;
    flex-Wrap: wrap;
    justify-content: space-between;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 10px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
    background-color: "light-grey";
}

.course-select {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-self: space-between;
    /* align-items: bottom; */

    /* Size */
    /* height: auto; */
    min-width: 200px;

    /* Margin, padding and border */
    margin: 0px 5px 5px 5px;
    padding: 10px 0px 10px 5px;

    /* Colours & fonts */
}

.course-label {
    /* Layout */
    text-align: left;

    /* Size */

    /* Margins & padding */
    margin: 0px 10px 5px 0px;
    padding: 0px 0px;

    /* Colours & fonts */
    color:black;
    font-size: 20px;
    font-weight: normal;
}

.course-selection {
    /* Display*/
    vertical-align: top;

    /* Size */
    width: 345px;
    height: 40%;
   
    /* Margins & padding */
    margin: 15px 15px 15px 0px;
    padding: 0px 5px 5px 5px;

    /* Colours & fonts */
    border-color: lightgrey;
    background-color: rgb(255, 248, 220);
    color: rgb(0, 200, 255);
    font-size: x-large;
    font-weight: bold;
}

.switch-box {
    /* Layout */
    display: flex;
    direction: row;
    justify-content: space-between;
    gap: 15px;

    /* Size */
    max-height: 75px;

    /* Borders, Padding & Margins */
    margin: 70px 10px 10px 10px;
    padding: 0px 0px 0px 0px;

    /* Colours and Fonts */
    /* background-color: lightgrey;  */
    
}

.switch-box-mobile {
    /* Layout */
    display: flex;
    flex-direction: row;
    /* justify-content: end; */
    justify-self: end;
    gap: 15px;

    /* Size */
    max-height: 90px;

    /* Borders, Padding & Margins */
    margin: 10px 10px 10px 10px;
    padding: 0px 0px 0px 0px;

    /* Colours and Fonts */
    /* background-color: lightgrey; */

}

/* Not Used */
.switch {
    /* Layout */
    display: flex;
    direction: row;
    justify-content: space-between;
    gap: 5px;

    /* Size */
    /* max-height: 40px; */
    min-width: 60px;
    min-height: 40px;

    /* Borders, Padding & Margins */
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;

    /* Colours and Fonts */
    font-size: "large";
    background-color: black;
}

.course-buttons {
    /* Layout */
    align-items: right;
    display: flex;
    flex-direction: row;
    align-self: end;
    gap: 15px;

    /* Borders, Padding & Margins */
    margin: 0px 0px 10px 10px;
    /* Size */
    height: 35px;
    width: 80px;
    
    /* Colours and Fonts */
    /* background: rgb(255, 248, 220); */
    /* background: darkgreen; */
}

/* *********************************** ADMIN COURSE MAIN FIELDS *************************************** */
/* **************************************************************************************************** */

.course-frame {
    /* Layout */
    /* display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr; */
    gap: 5px;

    /* size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Borders, Margins & Padding */
    /* margin: 5px; */
    padding: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    /* Colours & Fonts */
    background-color: inherit;
    /* background-color: lightblue; */
}

.course-frame-mobile {
    /* Layout */
    /* display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr; */
    gap: 5px;

    /* size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Borders, Margins & Padding */
    /* margin: 5px; */
    padding: 0px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */

    /* Colours & Fonts */
    background-color: inherit;
    /* background-color: lightblue; */
}

.course-Summary-container {
    /* Layout */
    /* grid-column: 1;
    grid-row: 1; */
 
    /* size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Borders, Margins & Padding */
    margin: 10px 10px 0px 10px;
    
   
    /* Colours & Fonts */
    background-color: inherit
    /* background-color: lightgrey; */
}

.course-Summary-container-mobile {
    /* Layout */
    grid-column: 1;
    grid-row: 1;
 
    /* size */
    height: auto;
    width: auto;
    min-width: 380px;

    /* Borders, Margins & Padding */
    
   
    /* Colours & Fonts */
    background-color: inherit
    /* background-color: lightgrey; */
}

.courses-tile-mobile {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


    /* size */
    max-width: 70px;
    max-height: 70px;

    /* Borders, Margins & Padding */
    /* margin: 1px, 2px, 2px, 2px; */
    /* padding: 3px;   */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    /* border-radius: 20px; */

    /* Colours & Fonts */
    /* background-color: rgb(255,248,220); */
    /* background-color: white; */
    /* background-color: rgb(255,248,220); */
    /* background-color: white; */
    /* background-color: rgb(245,245,245); */
    color: black;
    /* color: rgb(0,102,204); */
    /* font-size: medium-small; */
    /* font-weight: bold; */

}

.course-icon-box {
    /* Layout */
    vertical-align: bottom;

    /* size */
    max-width: 70px;
    max-height: 70px;

    /* Borders, Margins & Padding */
    margin-bottom: 0px;
    padding: 0px;

    /* Colours & Fonts */
    /* background-color: violet; */

}

.course-icon-box-mobile {
    /* Layout */
    vertical-align: bottom;

    /* size */
    width: 70x;
    height: 70px;

    /* Borders, Margins & Padding */
    margin-bottom: 0px;
    padding: 0px;

    /* Colours and fonts */
    /* background-color: violet; */
}

.course-icon-box-large-mobile {
    /* Layout */
    vertical-align: bottom;

    /* size */
    width: 85px;
    height: 75px;

    /* Borders, Margins & Padding */
    margin-bottom: 0px;
    padding: 0px;

    /* Colours and fonts */
    /* background-color: violet; */
}

.course-fields-container {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;

    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 0px 0px 10px 0px ;
    padding: 0px 10px 25px 15px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    /* padding: 5px 5px 5px 5px; */
   
    /* Colours & Fonts */
    background-color: inherit;
    /* background-color: lightgrey; */
}

.course-fields-container-mobile {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;

    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 0px 0px 20px 0px ;
    padding: 0px 15px 25px 15px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    /* padding: 5px 5px 5px 5px; */
   
    /* Colours & Fonts */
    background-color: inherit;
    /* background-color: violet; */
}

.course-subtitles {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-between;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 0px 0px;

    /* Colours & Fonts */
    font-size: large;
    background-color: white;
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-subtitles-2 {
    /* Layout */
    display: flex;
    flex-Direction: row;
    justify-content: space-between;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 40px 10px 0px 0px;

    /* Colours & Fonts */
    font-size: large;
    background-color: inherit;
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-field-row {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: 20px;

    /* size */
    /* width: 100%; */

    /* Borders, Margins & Padding */
    margin: 0px, 10px, 0px, 10px;

    /* Colours & Fonts */
    /* background-color: lightgray; */
    background-color: inherit;
}

.course-fields {
    /* Layout */
    grid-row: 2;
    grid-column: 1 / -2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;;
    gap: 25px;

    /* size */
    /* height: auto; */
    min-height: 40px;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-field-wide {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 800px;
    min-width: 800px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-field-wide-mobile {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 350px;
    min-width: 350px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-field {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    width: 340px;
    /* min-width: 200px; */
    min-height: 40px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-field-narrow {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 100px;
    min-width: 100px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 0px;
    /* margin: 10px 10px 10px 10px; */
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-date-field {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 200px;
    min-width: 200px;
    min-height: 40px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-select-field {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 200px;
    min-width: 200px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 0px 5px 0px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-select-field-narrow {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 100px;
    min-width: 100px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 0px 5px 0px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-field-display {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 150px;
    min-width: 150px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px lightgrey;
    border-radius: 10px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-size: small;
    font-family: arial;
    word-wrap: normal;
    background-color: green;
}

.select-options {
    /* background: rgb(245,245,245); */
    /* background-color: white; */
    text-align: middle;
    vertical-align: bottom;
    /* width: 100%;
    height: 100%; */
    min-width: 75px;
    height: 57px;
    font-family: Arial, Helvetica, sans-serif;
}

/* *********************************** ADMIN COURSE MANAGEMENT BUTTONS *************************************** */
/* *********************************************************************************************************** */

.course-assignment-buttons-frame {
    /* Layout */
    grid-column: 1 / 26;
    grid-row: 2;
    display: flex;
    direction: row;
    justify-content: end;
    gap: 0px;

    /* Size */
    /* max-height: 75px; */

    /* Borders, Padding & Margins */
    /* margin: 5px 5px 5px 5px; */
    margin: 0px 15px 10px 0px;
    padding: 0px 0px 0px 0px;

    /* Colours and Fonts */
    /* background-color: lightgrey;  */
    
}

.course-assignment-buttons-frame-mobile {
    /* Layout */
    grid-column: 1 / 26;
    grid-row: 2;
    display: flex;
    flex-direction: row;
    /* justify-content: end; */
    justify-self: end;
    gap: 0px;

    /* Size */
    /* max-height: 90px; */

    /* Borders, Padding & Margins */
    /* margin: 5px 5px 5px 5px; */
    margin: 0px 15px 20px 0px;
    padding: 0px 0px 0px 0px;

    /* Colours and Fonts */
    /* background-color: lightgrey; */

}

.course-delete-button {
    /* Layout */
    align-items: right;
    justify-self: bottom;
    display: flex;
    flex-direction: column;
    align-self: end;
    gap: 0px;

    /* Borders, Padding & Margins */
    margin: 0px 0px 0px 0px;
    /* padding: 0px 0px 30px 10px; */
    /* Size */
    height: 20px;
    width: 200px;
    
    /* Colours and Fonts */
    /* color: "primary" */
    /* background: rgb(255, 248, 220); */
    /* background: darkgreen; */
    /* background-color: lightgrey; */
}

/* *********************************** ADMIN COURSE APPROVALS *************************************** */
/* ************************************************************************************************** */

.course-approvals-container {
    /* Layout */
    grid-column: 25/26;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    /* grid-template-columns: repeat (4, 1fr); */
    
    /* size */
    height: auto;
    /* max-width: 225px; */
    min-width: 225px;

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 0px 5px 5px 15px;
   

    /* Colours & Fonts */
    background-color: white;
}

.course-approvals-container-mobile {
    /* Layout */
    grid-column: 1/26;
    grid-row: 4;
    
    /* grid-row: 1; */
    /* grid-template-columns: repeat (4, 1fr); */
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 0px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 5px 5px 5px 20px;
   

    /* Colours & Fonts */
    background-color: white;
}

.course-status-box {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-self: center;

    /* size */
    width: 150px;

    /* Borders, Margins & Padding */
    margin: 5px 0px 10px 15px;

    /* Colours & Fonts */
    font-size: medium;
    color: white;
    /* font-weight: bold; */
    font-family: arial;
    /* background-color: white; */
}

.course-status-chip {
    /* Layout */

    /* size */
    width: 150px;

    /* Borders, Margins & Padding */
    margin: 0px 0px 5px 0px;

    /* Colours & Fonts */
    font-size: medium;
    color: white;
    /* font-weight: bold; */
    font-family: arial;
    /* background-color: white; */
}

.course-footnote {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-content: center; */
    /* background-color: rgb(255,248,220); */
    /* border: rgb(255, 248, 220); */
    font-size: small;
    color: black;
    font-weight: normal;
    font-style: italic;
    margin: 0px 5px;
    padding: 0px 0px 5px 0px;
}

.course-approval-button {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: auto;
    min-width: 185px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 0px 15px 0px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-field-box-approval {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-self: start;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 350px;
    min-width: 185px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 15px 0px 15px 5px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-select-field-approval {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 190px;
    min-width: 175px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 0px 10px 25px 5px;
   
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.course-date-field-approval {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 200px;
    min-width: 200px;
    /* min-height: 40px; */
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 0px 0px 0px 0px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}
/********************************** ADMIN COURSE SECTIONS AND LESSONS ***************************** */
/* ************************************************************************************************ */

.course-lessons-container {
    /* Layout */
    /* display: flex;
    flex-direction: row;
    justify-content: start; */
    grid-column: 1/26;
    grid-row: 3;

    display: flex;
    flex-direction: column;    
    justify-content: flex-start;
    /* flex-wrap: wrap; */
    /* gap: 25px; */
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 10px 10px 10px 10px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 10px 10px 10px 15px;
   
    /* Colours & Fonts */
    background-color: white;
}

.course-lessons-container-mobile {
    /* Layout */
    /* display: flex;
    flex-direction: row;
    justify-content: start; */
    grid-column: 1/26;
    grid-row: 3;

    /* display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    flex-wrap: wrap; */
    /* gap: 25px; */
    
    /* size */
    height: auto;
    width: auto;

    /* Borders, Margins & Padding */
    margin: 0px 0px 10px 0px;
    border: solid 1px;
    border-color: lightgrey;
    border-radius: 10px;
    padding: 10px 10px 10px 15px;
   
    /* Colours & Fonts */
    background-color: white;
}

.section-subtitles {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
 
     /* size */
     width: auto;
     /* min-width: 220px; */
 
     /* Borders, Margins & Padding */
     margin: 10px 10px 20px 0px;
     padding: 3px 3px 3px 8px;
 
     /* Colours & Fonts */
     font-size: medium;
     /* background-color: inherit; */
     background-color:aliceblue;
     font: black;
     font-weight: bold;
     font-family: arial;
     word-wrap: normal;
 }
 
 .new-lesson-subtitles {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
 
     /* size */
     width: auto;
     /* min-width: 220px; */
 
     /* Borders, Margins & Padding */
     margin: 10px 10px 10px 0px;
     padding: 3px 3px 3px 8px;
 
     /* Colours & Fonts */
     font-size: medium;
     /* background-color: inherit; */
     background-color:inherit;
     font: black;
     font-weight: bold;
     font-family: arial;
     word-wrap: normal;
 }

 .lessons-container {
     /* Layout */

    display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
 
     /* size */
     width: auto;
     
 
     /* Borders, Margins & Padding */
     margin: 10px 10px 25px 0px;
 
     /* Colours & Fonts */
     font-size: medium;
     background-color: inherit;
     /* background-color: lightgray; */
     font: black;
     font-weight: bold;
     font-family: arial;
     word-wrap: normal;
 }

 .lesson-last-row-container {
    /* Layout */

    display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 20px;

    /* size */
    width: auto;
    

    /* Borders, Margins & Padding */
    margin: 0px 10px 0px 0px;

    /* Colours & Fonts */
    font-size: medium;
    background-color: inherit;
    /* background-color: lightgray; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;

 }

.lesson-field-wide {
    /* Layout */


    /* size */
    /* height: auto; */
    /* width: 800px; */
    /* width: auto; */
    min-width: 350px;
    max-width: 100%;

    /* Borders, Margins & Padding */
    /* margin: 5px 0px 5px 0px; */
 
    /* Colours & Fonts */
    /* background-color: lightblue; */
    /* background-color: inherit; */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}


.lesson-delete-icon {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    justify-content: center;

    /* size */
    /* height: auto; */
    /* width: 800px; */
    /* width: auto; */
    width: auto;

    /* Borders, Margins & Padding */
    margin: 5px 0px 5px 0px;
 
    /* Colours & Fonts */
    /* background-color: lightblue; */
    background-color: inherit;
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}
.new-lesson-button {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: end;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: auto;
    min-width: 185px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 0px 5px 5px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

