.courses-page {
    /* Layout */
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;

    /* Size */
    min-width: 350px;
    min-height: 650px;

    /* Margin, padding and border */
    margin: 10px 5px 5px 5px;
    padding: 0% 0% 0% 0%;
    /* border: 1px solid grey; */

    /* Colours & fonts */
    background-color: white;
    font-family: arial, helvetica, 'sans serif';
    color:black;
    font-weight: bold;
    font-size: xx-large;
}

.courses-frame {
    /* Layout */
    display: flex;
    flex: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
   

    /* size */
    min-width: 300px;
    height: auto;

    /* Borders, Margins & Padding */
    margin: 80px, 5px, 5px, 5px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* border: solid 1px lightgray; */
    border-radius: 25px;
    border: solid, 1px, lightgray;

     /* Colours & Fonts */
    font: black;
    /* background-color: rgb(255,248,220); */
    /* background-color:white; */
    /* background-color: rgb(235, 230, 230); */
    /* background-color: rgb(255,248,220); */
    background-color: white;

}

.course-selection-title-row {
    /* Layout */
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    flex-wrap: wrap;

    /* size */

    /* border: solid 1px lightgray; */
    margin-top: 70px;

    /* Colours and fonts */
    /* color: rgb(113, 3, 3); */
    /* font-weight: bold;
    font-family: arial;
    font-size: 60px;  */
    
    
}

.course-selection-title {
    /* Layout */
    /* flex-direction: row; */
    /* justify-content: space-between; */

    /* size */

    /* border: solid 1px lightgray; */
    /* margin-top: 70px; */
    margin-left: 10px;

    /* Colours and fonts */
    color: rgb(113, 3, 3);
    font-weight: bold;
    font-family: arial;
    font-size: 60px; 
    /* background-color: lightGrey; */
    
}

.course-selection-radio {
    /* Layout */
    flex-direction: "row";
    justify-content: 'flex-start';


    /* size */

    /* border: solid 1px lightgray; */
    margin-top: 15px;
    margin-left: 20px;

    /* Colours and fonts */
    color: rgb(113, 3, 3);
    /* color: black; */
    font-weight: normal;
    font-family: arial;
    /* font-size: 40px;  */
    /* background-color: lightGrey; */
}

.banner .titleDesktop {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    padding: 10px;

     /* Colours & fonts */
    font-size: 70px;
    text-align: left;
}

.banner .titleMobile {
    /* Cell A:1 of Banner Grid*/
    justify-self: start;
    align-items: top;

    /* Size */
    max-height: 100px;

    /* Margin, padding and border */
    padding: 10px 0px 10px 10px;

     /* Colours & fonts */
    font-size: 40px;
    text-align: left;
}

.courses-button {
    /* Layout */
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */

    /* size */
    width: auto;
    height: auto;

    /* Borders, Margins & Padding */
    margin: 0px;
    padding: 0px;  
   

    /* Colours & Fonts */
    /* background-color: rgb(255,248,220); */
    /* background-color: white;
    color: black;
    font-size: large;
    font-weight: bold; */

}

.courses-tile {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* size */
    width: 230px;
    height: 230px;

    /* Borders, Margins & Padding */
    padding: 10px;
    margin: 10px, 5px, 2px, 5px;
    /* padding: 10px, 5px, 2px, 5px;  */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 20px;

    /* Colours & Fonts */
    /* background-color: rgb(255,248,220); */
    background-color: white;
    /* background-color: rgb(255,248,220); */
    /* background-color: rgb(245,245,245); */
    color: black;
    /* color: rgb(0,102,204); */
    font-size: large;
    font-weight: bold;

}

.courses-tile-mobile {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* size */
    min-width: 120px;
    min-height: 125px;

    /* Borders, Margins & Padding */
    margin: 1px, 2px, 2px, 2px;
    padding: 0px;  
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 20px;

    /* Colours & Fonts */
    /* background-color: rgb(255,248,220); */
    background-color: white;
    /* background-color: black; */
    /* background-color: rgb(255,248,220); */
    /* background-color: rgb(245,245,245); */
    color: black;
    /* color: rgb(0,102,204); */
    font-size: 12px;
    font-weight: bold;

}

.courses-tile-disabled {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* size */
    width: 230px;
    height: 230px;

    /* Borders, Margins & Padding */
    padding: 10px;
    margin: 10px, 5px, 2px, 5px;
    /* padding: 10px, 5px, 2px, 5px;  */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 20px;

    /* Colours & Fonts */
    /* background-color: rgb(255,248,220); */
    /* background-color: lightgrey; */
    background-color: white;
    /* background-color: rgb(255,248,220); */
    /* background-color: rgb(245,245,245); */
    color: lightgrey;
    /* color: rgb(0,102,204); */
    font-size: large;
    font-weight: bold;

}

.courses-tile-mobile-disabled {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* size */
    min-width: 120px;
    min-height: 125px;

    /* Borders, Margins & Padding */
    margin: 1px, 2px, 2px, 2px;
    padding: 0px;  
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 20px;

    /* Colours & Fonts */
    /* background-color: rgb(255,248,220); */
    /* background-color: lightgrey; */
    background-color: white;
    /* background-color: rgb(255,248,220); */
    /* background-color: rgb(245,245,245); */
    color: grey;
    /* color: rgb(0,102,204); */
    font-size: 12px;
    font-weight: bold;

}


.courses-icon {
    /* Layout */
    vertical-align: bottom;

    /* size */
    width: 90px;
    height: 80px;

    /* Borders, Margins & Padding */
    margin-bottom: 8px;
    padding: 0px;
}

.courses-icon-mobile {
    /* Layout */
    vertical-align: bottom;

    /* size */
    width: 60px;
    height: 50px;

    /* Borders, Margins & Padding */
    margin-bottom: 8px;
    padding: 0px;
}

.courses-icon-disabled {
    /* Layout */
    vertical-align: bottom;

    /* size */
    width: 90px;
    height: 80px;

    /* Borders, Margins & Padding */
    margin-bottom: 8px;
    padding: 0px;
}

.courses-icon-mobile-disabled {
    /* Layout */
    vertical-align: bottom;

    /* size */
    width: 60px;
    height: 50px;

    /* Borders, Margins & Padding */
    margin-bottom: 8px;
    padding: 0px;
}