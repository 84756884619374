

.auditLog {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 20px 0px 0px 0px;
    width: auto;
    box-sizing: border-box;
}

.admin-camoLog-frame {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; 

    /* size */
    height: auto;


    /* Borders, Margins & Padding */
    margin: 0px;
    padding: 0px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */

    /* Colours & Fonts */
    background-color: white;
}

.admin-camoLog-table {
    /* Layout */
 
    /* size */
    height: auto;
    min-width: 388px;
    

    /* Borders, Margins & Padding */
    margin: 5px 0px, 5px 0px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    /* Colours & Fonts */
    background-color: white;
}

/* #box {
    background-color: white;
    /* background-color: RGB(255,248,220); */
    /* border: 1px solid gray; */
    /* color: black;
    padding: 70px 0 0 0;
    margin: 10px;
    width: auto;
} */