* {
    box-sizing: border-box;
}


/****************************** BANNER  ******************************/

/* Use Banner.css */

/* Use Departure.css */

/****************************** COMMON ******************************/

.feedback {
    /* Layout */
    position: sticky;
    bottom: "60px";
    left: "10px";
    z-Index: "5";
    flex-Direction: 'row';
    justify-Content: 'flex-start';
    flex-Wrap: 'wrap';
   

    /* Borders, margins and padding */
    margin: 10px;
    
    /* Colours & fonts */
    
}

.note {
    font-style: italic;
    color: black;
    background-color: white;
    font-size: small;
    font-weight: normal;
    width: auto;
    padding: 0px 0 0 0px;
    vertical-align: top;
    align-items: center;
}



