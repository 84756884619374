/****************************** DEPARTURE  ******************************/

.departure {
    /* Layout */
    z-index: 5;
    grid-area: departure;
        display: grid;
        grid-template-areas:
        "dep-image  dep-details";
        grid-template-columns: 220px 1fr;

    /* Sizing */
    width: 100vw;
    height: auto;
  
    /* Margins & padding */
    margin: 10px 0px 10px 0px;

    /* Colours & Fonts   */
    font-family: arial, helvetica, 'sans serif';
    background: white;
 
}

.departure-mobile {
    /* Layout */
    /* z-index: 0; */
    grid-area: "departure";
        display: grid;
        grid-template-areas:
        "dep-image" 
        "dep-details";
        grid-template-rows: 8% 92%;
    
    /* Sizing */
    width: auto;
    height: auto;
  
    /* Margins & padding */
    margin: 10px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    /* Colours & Fonts   */
    font-family: arial, helvetica, 'sans serif';
    background-color: white;
    
}

/************************ DEPARTURE IMAGE BOX *************************/

.dep-image {
    /* Layout */
    grid-area: "dep-image";

    /* Size */
    height: auto;
    width: auto;

    /* Borders, margins & padding */
    margin: 0px 10px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px;

    /* Colours & fonts */
    color:rgb(113, 3, 3);
    font-size: xx-large;
    /* background-color: RGB(255,248,220); */
    /* background-color: rgb(255, 253, 231); */
    background-color: rgb(254, 255, 237);
    vertical-align: top;
}

.dep-image-mobile {
    /* Layout */
    z-index: 4;
    position: sticky;
    top: 60px;

    /* Size */
    width: auto;
    min-height: 80px;
    max-height: 80px;

    /* Borders, margins & padding */
    margin: 0px 0px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px 0px 0px 0px;

    /* Colours & fonts */
    color:rgb(113, 3, 3);
    font-size: x-large;
    /* background-color: RGB(255,248,220); */
    background-color: rgb(254, 255, 237);
    vertical-align: top;
}

.dep-image .dep-title {
    /* background-color: RGB(255,248,220); */
    background-color: rgb(254, 255, 237);
    margin: 10px 10px 0px 10px;
    padding: 5px;
}

.dep-image-mobile .dep-title-mobile {
    background-color: rgb(254, 255, 237);
    /* background-color: RGB(255,248,220); */
    margin: 25px 10px 0px 10px;
    padding: 5px;
    vertical-align: middle;
}

.dep-image-mobile .aircraft-header-mobile {
    /* background-color: RGB(255,248,220); */
    background-color: rgb(254, 255, 237);
    margin: 25px 10px 0px 15px;
    padding: 5px;
    vertical-align: middle;
}

.dep-image img {
    /* background-color: RGB(255,248,220); */
    background-color: rgb(254, 255, 237);
    max-height: 160px;
    min-width: 150px;
    margin: 0px 10px 0px 10px
}

.departure-mobile .dep-image-mobile img {
    background-color: rgb(254, 255, 237);
    /* background-color: RGB(255,248,220); */
    max-width: 13%;
    margin: 0px 10px 0px 40px
}

/************************ DEPARTURE DETAILS *************************/

.dep-details {
    /* Layout */
    grid-area: dep-details;
    display: grid;
    grid-template-rows: [row1] 0.1fr [row2] 0.85fr [row3] 0.05fr;
   
    /* Sizing */
    width: auto;
    height: auto;

    /* Margins & padding */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px;
    margin:  0px 20px 0px 0px;

    /* Colours & Fonts   */
    font-family: arial, helvetica, 'sans serif';
    background: white;
    /* background-color: grey; */
}

.departure-mobile .dep-details-mobile {
    /* Layout */
    grid-area: dep-details;
    display: grid;
    grid-template-rows: [row1] 0.2fr [row2] 0.75fr [row3] 0.05fr;

    /* Sizing */
    width: auto;
    /* min-height: 60px; */

    /* Margins & padding */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 0px;
    margin:  0px 0px 0px 0px;

    /* Colours & Fonts   */
    font-family: arial, helvetica, 'sans serif';
    background: white;
    /* background-color: lightgrey; */
}

/************************ DEPARTURE DETAILS ROW 1 - Aircraft Header *************************/

.dep-details .dep-details-row1 {
    /* Size */
    
    height: auto;
    width: auto;

    /* Margin, padding and border */

    /* Colours & fonts */
    /* background-color: lightgrey; */
}

.dep-details-mobile .dep-details-row1 {
    /* Size */
   
    height: auto;
    width: auto;

    /* Margin, padding and border */

    /* Colours & fonts */
}

.aircraft-header {
   
    font-size: x-large;
    font-weight: bolder;
    width: 150px;
    height: fit-content;
    padding: 5px 5px 5px 5px;
    color: black;
    margin: 10px 10px 10px 10px;
    background-color: white;
    vertical-align: center;
}

.dep-details-mobile .aircraft-header {
    
    font-size: x-large;
    font-weight: bolder;
    width: 150px;
    height: fit-content;
    padding: 5px 5px 5px 5px;
    color: black;
    margin: 10px 10px 10px 15px;
    background-color: white;
    vertical-align: center;
}

/************************ DEPARTURE DETAILS ROW 2 - Tables, labels and fields *************************/

.dep-details .dep-details-row2 {
    /* Layout */
        /* display: flex;
        flex-Direction: row;
        flex-Wrap: wrap;
        justify-content: flex-start; */

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 10px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.dep-details-mobile .dep-details-row2 {
    /* Layout */
    /* grid-area: det-details; */
        /* display: flex;
        flex-Direction: row;
        flex-Wrap: wrap;
        justify-content: flex-start; */

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 20px 10px 0px 0px;
    padding: 0px;

    /* Colours & fonts */
}

.table-crewAndPAX {
    background: white;
    margin: 10px 10px 10px 10px;
    text-align: left;
    padding: 0px;
    vertical-align: middle;
    line-height: 18px;
    font-size: small;
    min-width: 350px;
    max-width: 450px;
}

.table-departureFuel {
    background: white;
    margin: 10px 30px 10px 10px;
    text-align: left;
    padding: 0px;
    vertical-align: middle;
    line-height: 18px;
    font-size: small;
    max-width: 210px;
}

.table-Oil {
    background: white;
    margin: 10px 10px 10px 10px;
    text-align: left;
    padding: 0px;
    vertical-align: middle;
    line-height: 18px;
    font-size: small;
    max-width: 210px;
}

.dep-table-header {
    line-height: 4px;
}

.label {
    background:rgb(113, 3, 3);
    
    color: white;
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    width: 70px;
    min-height: 65px;
    font-weight: bold;
    border: 0px solid white;
}

.labelLong {
    background:rgb(113, 3, 3);
    color: white;
    padding: 5px 5px 5px 5px;
    vertical-align: middle;
    min-width: 150px;
    height: 40px;
    font-weight: bold;
    border: 0px solid white;
}

.input { 
    background: white;
    text-align: middle;
    min-width: 60px;
    height: 40px;
    border: 0px solid white;
}

.inputDate { 
    background: white;
    text-align: middle;
    min-width: 150px;
    max-width: 150px;
    height: 40px;
    border: 0px solid white;
}

.inputWide { 
    background: white;
    text-align: middle;
    min-width: 220px;
    max-width: 150px;
    height: 40px;
    border: 0px solid white;
}

.inputNum {
    background: white;
    text-align: middle;
    min-width: 75px;
    height: 40px;
    border: 0px solid white;
}

error {
    background: rgb(252,252,88);
    text-align: middle;
    min-width: 75px;
    height: 40px;
    border: 0px solid white;
}

.inputGrey{ 
    background: rgb(245, 245, 245);
    text-align: middle;
    min-width: 75px;
    height: 40px;
    border: 0px solid white;
}

.selection {
    background-color: rgb(245, 245, 245);
    /* background-color: white; */
    text-align: middle;
    vertical-align: bottom;
    width: 60px;
    height: 40px;
    border: 0px solid white;
}

.oil {
    /* background: rgb(245,245,245); */
    /* background-color: white; */
    text-align: middle;
    vertical-align: bottom;
    /* width: 100%;
    height: 100%; */
    min-width: 75px;
    height: 57px;
}

.oilCell {
    background-color: rgb(245,245,245);
    /* background-color: white; */
    text-align: middle;
    vertical-align: bottom;
    width: 75px;
    height: 58px;
    padding: 0px solid white;
    border: 0px solid red;
}

.oilCellBlank {
    background-color: white;
    /* background-color: white; */
    text-align: middle;
    vertical-align: bottom;
    width: 75px;
    height: 58px;
    padding: 0px solid white;
    border: 0px solid white;
}

.selectionBlank {
    /* background: rgb(245, 245, 245); */
    background: white;
    text-align: middle;
    vertical-align: middle;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 70px;
    height: 55px;
    /* border: 2px solid white; */
}

/************************ DEPARTURE DETAILS ROW 2 - Foot Notes & Blanks *************************/

.LR {
    background: white;
    text-align: left;
    vertical-align: bottom;
    padding: 3px 0 0px 5px;
    width:40px;
    max-height: 20px;
    color: black;
    font-weight: bold;
    font-size: small;
}

.fuelCapacity {
    background: white;
    text-align: center;
    vertical-align: center;
    padding: 8px 0 0 0px;
    margin: 0px 20px 0px 0px;
    color: black;
    font-weight: bold;
    font-size: small; 
}

.blank {
    background:white;
    width: 30px;
    max-width: 200px;
    border: 0px solid white;
}

.blank2 {
    background: white;
    width: 30px;
    max-width: 200px;
    border: 0px solid white;
}

/************************ DEPARTURE DETAILS ROW 2 - Images *************************/


.petrolImg {
    background: white;
    height: 40px;
    width: 40px;
    align-items: center;
    vertical-align: bottom;
    padding: 2px 0 3px 0;
}

.oilImg {
    background: white;
    height: 50px;
    width: 50px;
    align-items: center;
    vertical-align: bottom;
    padding: 2px 0 0 0;
}

/************************ DEPARTURE DETAILS ROW 3 - Button *************************/
.dep-details .dep-details-row3 {
    /* Layout */
    /* grid-area: "dep-details-row3"; */
        display: flex;
        flex-Direction: row;
        flex-Wrap: wrap;
        justify-content: flex-end;
       
    /* Size */
    max-height: 80px;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 10px 0px;

    /* Colours & fonts */
    
}

.dep-details-mobile .dep-details-row3 {
    /* Layout */
    /* grid-area: "dep-details-row3"; */
        display: flex;
        flex-Direction: row;
        flex-Wrap: wrap;
        justify-content: flex-end;

    /* Size */
    height: auto;
    width: auto;

    /* Margin, padding and border */
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;

    /* Colours & fonts */
    
}

.dep-details-row3 .buttonOpen {
    background: darkgreen;
    margin: 0px 10px 10px 0px;
    padding: 0px 0px 0px 0px;
    width: 90px;
    align-items: right;
    min-height: 35px;
}

.dep-details-row3 .button-open-flight-delete {
    /* background: darkgreen; */
    margin: 0px 10px 10px 0px;
    padding: 0px 0px 0px 0px;
    width: 160px;
    align-items: right;
    min-height: 25px;
    /* color: red; */
    font-size: small;
}


