.maintenance-window-container-mobile {
    /* Layout */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 0  px;

    /* size */
    height: auto;
    width: auto;
    /* min-width: 240px; */

    /* Borders, Margins & Padding */
    margin: 20px 0px 0px 0px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
    /* background-color: lightgray; */
}

.maintenance-chips-outer {
    /* Layout */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0  px;

    /* size */
    height: auto;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 0px 0px 0px 0px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.maintenance-chips {
    /* Layout */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;;
    gap: 15px;

    /* size */
    height: auto;
    width: auto;
    min-width: 360px;

    /* Borders, Margins & Padding */
    margin: 5px 5px 0px 5px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
    /* background-color: lightgray;     */
}

.maintenance-chips-inner {
    /* Layout */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;

    /* size */
    height: auto;
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 5px 5px 5px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-family: arial;
    word-wrap: normal;
}

.maintenance-chips-heading {
    /* Layout */
    text-align: center;

    /* Size */

    /* Margins & padding */
    margin: 6px 0px 0px 5px;
    padding: 0px 0px;

    /* Colours & fonts */
    /* color: black; */
    /* color:rgb(113, 3, 3); */
    /* color:rgb(78, 77, 77); */
    color:black;
    /* color: black; */
    font-size: 20px;
    font-weight: normal;
}

.maintenance-chips-subtitles-banner {
    /* Layout */
    /* grid-row: 1;
    grid-column: 1 / -2; */
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-evenly;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 0px 0px 0px 0px;

    /* Colours & Fonts */
    font-size: small;
    color: black;
    font-weight: bold;
    font-family: arial;
    /* background-color: white; */
}

.maintenance-chips-subtitles {
    /* Layout */
    grid-row: 1;
    grid-column: 1 / -2;
    /* align-items: left; */
    display: flex;
    flex-Direction: row;
    justify-content: space-evenly;

    /* size */
    width: auto;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 0px 10px 0px 10px;

    /* Colours & Fonts */
    font-size: small;
    color: black;
    font-weight: bold;
    font-family: arial;
    /* background-color: white; */
}

.maintenance-chips-display {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    /* gap: 25px; */

    /* size */
    /* height: auto; */
    max-width: 100px;
    min-width: 100px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 5px 5px 5px;
    /* border: solid 1px lightgrey; */
    border-radius: 25px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-size: small;
    font-family: arial;
    word-wrap: normal;
    /* background-color: green; */
}

.maintenance-chips-display-body {
    /* Layout */
    /* grid-row: 2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
    gap: 25px;

    /* size */
    /* height: auto; */
    max-width: 140px;
    min-width: 140px;
    /* min-width: 220px; */

    /* Borders, Margins & Padding */
    margin: 5px 5px 5px 5px;
    /* border: solid 1px lightgrey; */
    border-radius: 25px;
 
    /* Colours & Fonts */
    font: black;
    font-weight: bold;
    font-size: small;
    font-family: arial;
    word-wrap: normal;
    /* background-color: green; */
}
.maintenance-chips-footnote {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-content: center; */
    background-color: rgb(255,248,220);
    /* border: rgb(255, 248, 220); */
    font-size: x-small;
    color: black;
    font-weight: normal;
    font-style: italic;
    margin: 0px 5px;
    padding: 0px 0px 5px 0px;
}